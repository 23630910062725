body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#ae_launcher {
    padding-bottom: 30px !important;
}

#localize-widget {
	z-index: 2147483647 !important
}